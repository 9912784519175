import React from 'react';
import PropTypes from 'prop-types';
import { Header, HeadingH1, Holder, Icon, IconButton, S } from 'poly-book';
import { FilterByStatus } from 'poly-site-ui';

import { UpRow } from '../../styled.js';
import { STAFF_FILTERS } from '../../constants/staff.js';
import TableSearchContainer from '../../containers/staff/search.js';
import { PrintAndExportButtons } from '../table/print-and-export-buttons.js';
import { exportStaffDirectoryToXLS } from './export-to-xls.js';
import { useStaffCountQuery } from '../../hooks/staff.js';

function FilterWrapper(props) {
  const { count } = useStaffCountQuery({
    typeFilter: props.typeFilter,
    clientId: props.clientId,
  });

  return <FilterByStatus {...props} count={count} />;
}

FilterWrapper.propTypes = {
  typeFilter: PropTypes.string,
  clientId: PropTypes.string,
};

function StaffFilter({ onClick, activeFilter, openAddStaffModal, clientId }) {
  return (
    <Header.Sub>
      <Header.Row>
        <Header.Block>
          <HeadingH1 lighter>Staff Directory</HeadingH1>
        </Header.Block>
        <Header.Block>
          <IconButton fill="#ff8d02" onClick={openAddStaffModal} hover>
            <S type="title">Add Staff Profile</S>
            <Icon name="add" fill="#ff8d02" dimensions={{ width: 30 }} />
          </IconButton>
        </Header.Block>
      </Header.Row>
      <UpRow>
        <Holder margin={20} center>
          <S type="badge" uppercase>
            Select:
          </S>
          <Holder margin={10}>
            {STAFF_FILTERS.map(({ type, ...props }) => (
              <FilterWrapper
                key={type}
                active={activeFilter === type}
                typeFilter={type}
                {...{ onClick, clientId }}
                {...props}
              />
            ))}
          </Holder>
        </Holder>
        <Header.Block>
          <PrintAndExportButtons
            printTitle="Staff Directory"
            exportFunction={exportStaffDirectoryToXLS}
            marginRight="20px"
          />
          <Holder margin={20}>
            <TableSearchContainer />
          </Holder>
        </Header.Block>
      </UpRow>
    </Header.Sub>
  );
}

StaffFilter.propTypes = {
  activeFilter: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  openAddStaffModal: PropTypes.func.isRequired,
  clientId: PropTypes.string,
};

export default StaffFilter;
