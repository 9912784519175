import {
  bool,
  func,
  shape,
  object,
  string,
  arrayOf,
  oneOfType,
} from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import Link from 'redux-first-router-link';
import { SupplierStatuses } from 'poly-constants';
import {
  IconButton,
  HeadingH3,
  HeadingH5,
  Heading,
  Button,
  Editor,
  Holder,
  Input,
  Modal,
  Grid,
  Icon,
  Pip,
  S,
} from 'poly-book';

import Select from '../select/index.js';
import ButtonLoader from '../loader/button.js';
import { Rows } from '../../util/forms/index.js';
import ServerError from '../server-error/index.js';
import InputNumber from '../input-number/index.js';
import { SUPPLIER } from '../../constants/routes.js';
import { MultipleInput } from '../multiple-input/index.js';
import { STATUS_COLORS } from '../../constants/suppliers.js';
import { formatPlaceholder } from '../../util/select.js';
import {
  addressObjectPropTypes,
  AddressSection,
} from '../address-section/index.js';

const Form = Modal.Item.withComponent(Grid);

function SimpleTitle({ label }) {
  return (
    <S type="content" title={label}>
      {label}
    </S>
  );
}

SimpleTitle.propTypes = { label: string.isRequired };

function ColoredTitle({ label }) {
  return (
    <Pip color={STATUS_COLORS[label.toLowerCase()]}>
      <S type="title">{label}</S>
    </Pip>
  );
}

ColoredTitle.propTypes = {
  label: string.isRequired,
};

const StyledH5 = styled(HeadingH5)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Wrapper = styled(Holder)`
  flex-direction: column;
  margin-top: 10px;
`;

const LinkS = styled(Link)`
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
`;

function SupplierNameInput({ names, ...props }) {
  return (
    <Wrapper>
      <Input {...props} />
      {names.map(({ _id: id, company }) => (
        // eslint-disable-next-line
        <LinkS
          to={{ type: SUPPLIER, payload: { id } }}
          key={id}
          target="_blank"
        >
          {R.prop('name', company)}
        </LinkS>
      ))}
    </Wrapper>
  );
}

SupplierNameInput.propTypes = {
  names: arrayOf(
    shape({
      _id: string.isRequired,
      company: shape({ name: string.isRequired }),
    }),
  ),
};

function AddEditSupplier({
  errors,
  loading,
  name,
  phone,
  address,
  emails,
  accEmails,
  activeField,
  activeAccField,
  fax,
  remarks,
  setRef,
  closeModal,
  onChange,
  validateOnBlur,
  resetError,
  onSubmit,
  isEdit,
  selectedStatus,
  selectStatus,
  onAddField,
  onAddAccField,
  onRemoveEmail,
  onRemoveAccEmail,
  setAddress,
  setActiveField,
  setActiveAccField,
  selectedType,
  setTypeSearch,
  setSelectedType,
  serviceTypes,
  typesLoading,
  names,
  onSetName,
}) {
  const items = [
    {
      title: {
        value: 'Supplier Name',
        props: ['required', 'description'],
      },
      error: R.prop('name', errors),
      item: (
        <SupplierNameInput
          name="name"
          placeholder="Supplier Name"
          maxLength={60}
          defaultValue={name}
          invalid={!!R.prop('name', errors)}
          onChange={(e) => {
            onSetName(e);
            onChange(e);
          }}
          {...{ names }}
        />
      ),
    },
    {
      item: (
        <AddressSection
          value={address}
          key="address-section"
          onChange={(newValue) => {
            setAddress(newValue);
            onChange({ target: { name: 'address', value: newValue } });
          }}
          onBlur={validateOnBlur}
          error={R.prop('address', errors)}
        />
      ),
    },
    {
      title: {
        value: 'Phone',
        props: ['required'],
      },
      error: R.prop('phone', errors),
      item: (
        <InputNumber
          name="phone"
          type="tel"
          placeholder="Phone"
          format="(###) ###-####"
          mask="_"
          defaultValue={phone}
          invalid={!!R.prop('phone', errors)}
          onBlur={validateOnBlur}
          onFocus={resetError}
        />
      ),
    },
    {
      title: {
        value: 'Fax#',
      },
      item: <Input name="fax" placeholder="Fax" defaultValue={fax} />,
    },
    {
      title: {
        value: 'Service Email',
        props: ['required', 'description'],
      },
      error: R.prop(activeField, errors),
      item: (
        <MultipleInput
          {...{
            onChange,
            errors,
            emails,
            onAddField,
            onRemoveEmail,
          }}
          name="email"
          addButtonText="Add Service Email"
          onFieldChoose={(e) => setActiveField(e.target.name)}
        />
      ),
    },
    {
      title: {
        value: 'Accounting Email',
        props: ['required', 'description'],
      },
      error: R.prop(activeAccField, errors),
      item: (
        <MultipleInput
          {...{ onChange, errors }}
          name="accEmail"
          emails={accEmails}
          onAddField={onAddAccField}
          onRemoveEmail={onRemoveAccEmail}
          addButtonText="Add Accounting Email"
          onFieldChoose={(e) => setActiveAccField(e.target.name)}
        />
      ),
    },
    {
      title: {
        value: 'Service Type',
      },
      item: (
        <Select
          value={selectedType}
          onChange={setSelectedType}
          onInputChange={setTypeSearch}
          placeholder={formatPlaceholder('Service Type')}
          isLoading={typesLoading}
          options={serviceTypes.map((serviceType) => ({
            value: serviceType._id,
            label: serviceType.name,
          }))}
          optionRenderer={SimpleTitle}
        />
      ),
    },
    {
      title: {
        value: 'Remarks',
        props: ['', 'description'],
      },
      item: (
        <Editor
          placeholder="Remarks"
          defaultValue={remarks}
          registerRef={setRef}
        />
      ),
    },
  ];
  const filteredItems = isEdit
    ? [
        {
          title: {
            value: 'Status',
          },
          item: (
            <Select
              value={selectedStatus}
              onChange={selectStatus}
              placeholder={formatPlaceholder('Status')}
              options={Object.values(SupplierStatuses).map((status) => ({
                value: status,
                label: status,
              }))}
              optionRenderer={ColoredTitle}
              clearable={false}
            />
          ),
        },
      ].concat(items)
    : items;
  return (
    <Modal show>
      <Modal.Item>
        <Heading>
          {isEdit ? (
            <StyledH5>{name}</StyledH5>
          ) : (
            <HeadingH3 lighter>Create Supplier</HeadingH3>
          )}
          <IconButton onClick={closeModal}>
            <Icon name="close" fill="#888b97" dimensions={{ width: 10 }} />
          </IconButton>
        </Heading>
      </Modal.Item>
      <ServerError error={R.prop('server', errors)} style={{ maxWidth: 450 }} />
      {isEdit && (
        <Modal.Item margin={20}>
          <Modal.Content>
            <HeadingH3 lighter>Edit Supplier</HeadingH3>
          </Modal.Content>
        </Modal.Item>
      )}
      <Modal.Item margin={20}>
        <HeadingH5>Supplier Details</HeadingH5>
      </Modal.Item>
      <form {...{ onSubmit }}>
        <Form columns="140px minmax(200px, 360px)" margin={30} simple>
          <Rows items={filteredItems} />
        </Form>
        <Modal.Buttons>
          <Button
            type="reset"
            mode="gray"
            onClick={closeModal}
            disabled={loading}
          >
            <S type="title">Cancel</S>
          </Button>
          <Button type="submit" mode="orange" disabled={loading}>
            {loading && <ButtonLoader />}
            <S type="title">{isEdit ? 'Save Changes' : 'Create Supplier'}</S>
          </Button>
        </Modal.Buttons>
      </form>
    </Modal>
  );
}

const emailsTypes = arrayOf(
  shape({
    id: string,
    email: string,
  }),
).isRequired;

AddEditSupplier.propTypes = {
  loading: bool.isRequired,
  name: string.isRequired,
  phone: string,
  address: addressObjectPropTypes,
  setAddress: func.isRequired,
  emails: emailsTypes,
  accEmails: emailsTypes,
  activeField: string.isRequired,
  activeAccField: string.isRequired,
  fax: string.isRequired,
  remarks: oneOfType([string, object]),
  closeModal: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  validateOnBlur: func.isRequired,
  setRef: func.isRequired,
  resetError: func.isRequired,
  errors: shape({
    address: string,
    phone: string,
    email: string,
    server: string,
  }),
  isEdit: bool,
  selectedStatus: oneOfType([
    shape({
      label: string,
      value: string,
    }),
    string,
  ]),
  selectStatus: func,
  onAddField: func.isRequired,
  onRemoveEmail: func.isRequired,
  setActiveField: func.isRequired,
  onAddAccField: func.isRequired,
  onRemoveAccEmail: func.isRequired,
  setActiveAccField: func.isRequired,
  selectedType: shape({
    label: string,
    value: string,
  }),
  setTypeSearch: func.isRequired,
  setSelectedType: func.isRequired,
  serviceTypes: arrayOf(
    shape({
      _id: string,
      name: string,
    }),
  ),
  typesLoading: bool.isRequired,
  names: arrayOf(
    shape({
      _id: string.isRequired,
      company: shape({ name: string.isRequired }),
    }),
  ),
  onSetName: func.isRequired,
};

AddEditSupplier.defaultProps = {
  errors: {},
  isEdit: false,
  selectedStatus: null,
  serviceTypes: [],
  selectedType: null,
  selectStatus: () => undefined,
  remarks: null,
  names: [],
};

export default AddEditSupplier;
