import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  HeadingH3,
  HeadingH5,
  TextArea,
  Heading,
  Button,
  Holder,
  Modal,
  Grid,
  Icon,
  S,
} from 'poly-book';

import ButtonLoader from '../../loader/button.js';
import { Title } from '../../../util/forms/index.js';
import InputNumber from '../../input-number/index.js';
import ServerError from '../../server-error/index.js';
import { ValidationMessage } from '../../../styled.js';
import { lessThen } from '../../../util/general.js';

const ADD = 'Add Material';
const EDIT = 'Edit Material';
const SAVE = 'Save Edits';

const ModalGrid = Modal.Item.withComponent(Grid);

function AddMaterial({
  materialsModal,
  activeProjectId,
  onQuantityChange,
  submitMaterial,
  onPriceChange,
  closeModal,
  onChange,
  loading,
  errors,
  total,
  isAdd,
}) {
  return (
    <Modal show>
      <Modal.Item>
        <Heading>
          <HeadingH5>{activeProjectId}</HeadingH5>
          <IconButton onClick={closeModal}>
            <Icon name="close" fill="#888b97" dimensions={{ width: 10 }} />
          </IconButton>
        </Heading>
      </Modal.Item>
      <ServerError error={R.prop('server', errors)} style={{ maxWidth: 430 }} />
      <Modal.Item>
        <Modal.Content>
          <HeadingH3 lighter>{isAdd ? ADD : EDIT}</HeadingH3>
        </Modal.Content>
      </Modal.Item>
      <form onSubmit={submitMaterial}>
        <ModalGrid columns="100px minmax(200px, 320px)" margin={30} simple>
          <Grid.Row>
            <Grid.Cell>
              <Title type="title" description required>
                Description
              </Title>
            </Grid.Cell>
            <Grid.Cell horizontal="stretch">
              <TextArea
                name="description"
                placeholder="Description"
                defaultValue={R.path(['data', 'description'], materialsModal)}
                invalid={!!R.prop('description', errors)}
                {...{ onChange }}
                autoFocus
              />
            </Grid.Cell>
            <ValidationMessage error={R.prop('description', errors)} />
          </Grid.Row>
          <Grid.Row>
            <Grid.Cell vertical="center">
              <Title type="title" required>
                Quantity
              </Title>
            </Grid.Cell>
            <Grid.Cell horizontal="stretch">
              <InputNumber
                name="quantity"
                isAllowed={lessThen(100000)}
                placeholder="Quantity"
                decimalScale={0}
                allowNegative={false}
                onChange={onQuantityChange}
                defaultValue={R.path(['data', 'quantity'], materialsModal)}
                invalid={!!R.prop('quantity', errors)}
              />
            </Grid.Cell>
            <ValidationMessage error={R.prop('quantity', errors)} />
          </Grid.Row>
          <Grid.Row>
            <Grid.Cell vertical="center">
              <Title type="title" required>
                Unit Price
              </Title>
            </Grid.Cell>
            <Grid.Cell horizontal="stretch">
              <InputNumber
                prefix="$"
                name="unitPrice"
                placeholder="$ 0.0"
                thousandSeparator=","
                decimalScale={2}
                isAllowed={lessThen(100000)}
                allowNegative={false}
                onChange={onPriceChange}
                defaultValue={R.path(['data', 'price'], materialsModal)}
                invalid={!!R.prop('unitPrice', errors)}
                fixedDecimalScale
              />
            </Grid.Cell>
            <ValidationMessage error={R.prop('unitPrice', errors)} />
          </Grid.Row>
          <Grid.Row>
            <Grid.Cell vertical="center">
              <S type="title">Total</S>
            </Grid.Cell>
            <Grid.Cell horizontal="stretch">
              <Holder margin={16}>
                <i />
                <HeadingH5>{`$ ${total}`}</HeadingH5>
              </Holder>
            </Grid.Cell>
          </Grid.Row>
        </ModalGrid>
        <Modal.Buttons>
          <Button
            type="reset"
            mode="gray"
            onClick={closeModal}
            disabled={loading}
          >
            <S type="title">Cancel</S>
          </Button>
          <Button type="submit" mode="orange" disabled={loading}>
            {loading && <ButtonLoader />}
            <S type="title">{isAdd ? ADD : SAVE}</S>
          </Button>
        </Modal.Buttons>
      </form>
    </Modal>
  );
}

AddMaterial.propTypes = {
  // eslint-disable-next-line
  materialsModal: PropTypes.object,
  activeProjectId: PropTypes.string.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  submitMaterial: PropTypes.func.isRequired,
  onPriceChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  isAdd: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    description: PropTypes.string,
    quantity: PropTypes.string,
    unitPrice: PropTypes.string,
    server: PropTypes.string,
  }),
};

AddMaterial.defaultProps = {
  materialsModal: null,
  errors: {},
};

export default AddMaterial;
