import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import React, { useCallback, useState } from 'react';
import { Header, HeadingH1 } from 'poly-book';

import { UpRow } from '../../styled.js';
import TechnicianStatsSearch from './search.js';
import DateSelector from '../../components/date-filter/date-selector.js';
import { PrintAndExportButtons } from '../../components/table/print-and-export-buttons.js';
import { exportTechnicianStatsToXLS } from './export-to-xls.js';
import {
  setTechnicianStatsPaginationCurrentPage,
  setTechnicianStatsTableSearchString,
  setTechnicianStatsDate,
} from '../../redux/actions/index.js';

function DateFilter({ title, ...props }) {
  return (
    <Header.Sub>
      <Header.Row>
        <Header.Block>
          <HeadingH1 lighter>{title}</HeadingH1>
        </Header.Block>
        <Header.Block>
          <PrintAndExportButtons
            printTitle={title}
            exportFunction={exportTechnicianStatsToXLS}
          />
        </Header.Block>
      </Header.Row>
      <UpRow>
        <Header.Block>
          <DateSelector {...props} />
        </Header.Block>
        <Header.Block>
          <TechnicianStatsSearch />
        </Header.Block>
      </UpRow>
    </Header.Sub>
  );
}

DateFilter.propTypes = {
  title: PropTypes.string.isRequired,
};

function TechnicianStatsFilter(props) {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onPreview = useCallback(() => {
    dispatch(setTechnicianStatsDate({ startDate, endDate }));
    dispatch(setTechnicianStatsTableSearchString(''));
    dispatch(setTechnicianStatsPaginationCurrentPage(1));
  }, [
    dispatch,
    startDate,
    endDate,
    setTechnicianStatsDate,
    setTechnicianStatsTableSearchString,
    setTechnicianStatsPaginationCurrentPage,
  ]);

  return (
    <DateFilter
      {...props}
      title="Technician Scorecard"
      onPreview={onPreview}
      changeStartDate={setStartDate}
      changeEndDate={setEndDate}
      startDate={startDate}
      endDate={endDate}
    />
  );
}

export default TechnicianStatsFilter;
