import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Header,
  HeadingH1,
  HeadingH4,
  Holder,
  S,
  Title,
} from 'poly-book';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { UPDATE_PROJECT_PERMISSION } from 'poly-security';

import BackArrow from '../back-arrow/index.js';
import ButtonLoader from '../loader/button.js';

const HeaderRow = styled(Header.Row)`
  min-height: 90px;
`;

function EditProjectInfoButton({ openEditProjectModal, disableEdit }) {
  const ifHasPermission = useHasUserAccessWithPermission(
    UPDATE_PROJECT_PERMISSION,
  );

  return (
    ifHasPermission && (
      <Button onClick={openEditProjectModal} disabled={disableEdit}>
        {disableEdit && <ButtonLoader />}
        <S type="title">Edit WO</S>
      </Button>
    )
  );
}

EditProjectInfoButton.propTypes = {
  openEditProjectModal: PropTypes.func.isRequired,
  disableEdit: PropTypes.bool.isRequired,
};

function ProjectInfo({ projectId, ...props }) {
  return (
    <Header.Sub>
      <HeaderRow>
        <Header.Block>
          <Holder margin={20}>
            <BackArrow />
            <Title>
              <Title.Block>
                <Title.Item>
                  <HeadingH1 lighter>{projectId}</HeadingH1>
                </Title.Item>
                <Title.Item>
                  <HeadingH4 lighter>Master Recurring Work Order</HeadingH4>
                </Title.Item>
              </Title.Block>
            </Title>
          </Holder>
        </Header.Block>
        <Header.Block>
          <EditProjectInfoButton {...props} />
        </Header.Block>
      </HeaderRow>
    </Header.Sub>
  );
}

ProjectInfo.propTypes = { projectId: PropTypes.string.isRequired };

export default ProjectInfo;
