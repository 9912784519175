import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  HeadingH1,
  HeadingH4,
  Holder,
  Header,
  Button,
  Title,
  S,
} from 'poly-book';

import InfoLoader from '../loader/info.js';
import BackArrow from '../back-arrow/index.js';
import ButtonLoader from '../loader/button.js';
import ClosePurchaseOrder from '../../containers/purchase-order/close-modal.js';
import EditPurchaseOrder from '../../containers/purchase-order/edit-po-request.js';

const HeaderRow = styled(Header.Row)`
  min-height: 90px;
`;

function PurchaseOrderInfo({
  purchaseOrder,
  loading,
  openEditModal,
  ifHasPermission,
}) {
  return (
    <>
      <Header.Sub>
        <HeaderRow>
          <Header.Block>
            <Holder margin={20}>
              <BackArrow />
              {loading ? (
                <InfoLoader />
              ) : (
                <Title>
                  <Title.Block>
                    <Title.Item>
                      <HeadingH1 lighter>
                        {R.prop('cardNumber', purchaseOrder)}
                      </HeadingH1>
                    </Title.Item>
                    <Title.Item>
                      <HeadingH4 lighter>Purchase Order</HeadingH4>
                    </Title.Item>
                  </Title.Block>
                </Title>
              )}
            </Holder>
          </Header.Block>
          <Header.Block>
            {ifHasPermission && (
              <Button onClick={openEditModal} disabled={loading}>
                {loading && <ButtonLoader />}
                <S type="title">Edit PO</S>
              </Button>
            )}
          </Header.Block>
        </HeaderRow>
      </Header.Sub>
      <EditPurchaseOrder />
      <ClosePurchaseOrder />
    </>
  );
}

PurchaseOrderInfo.propTypes = {
  loading: PropTypes.bool.isRequired,
  purchaseOrder: PropTypes.shape({
    cardNumber: PropTypes.string,
  }),
  openEditModal: PropTypes.func.isRequired,
  ifHasPermission: PropTypes.bool.isRequired,
};

PurchaseOrderInfo.defaultProps = {
  purchaseOrder: {},
};

export default PurchaseOrderInfo;
