import React from 'react';
import { Header, HeadingH1, Holder } from 'poly-book';
import styled from 'styled-components';

import TableSearchContainer from '../../containers/invoice-log/search.js';
import { PrintAndExportButtons } from '../table/print-and-export-buttons.js';
import { exportInvoiceLogToXLS } from './export-invoice-log-to-xls.js';

const Wrapper = styled(Header.Row)`
  padding: 15px 0;
`;

function InvoiceLogFilter() {
  return (
    <Header.Sub>
      <Wrapper>
        <Header.Block>
          <HeadingH1 lighter>Invoice Log</HeadingH1>
        </Header.Block>
        <Header.Block>
          <PrintAndExportButtons
            printTitle="Invoice Log"
            exportFunction={exportInvoiceLogToXLS}
            marginRight="20px"
          />
          <Holder margin={20}>
            <TableSearchContainer />
          </Holder>
        </Header.Block>
      </Wrapper>
    </Header.Sub>
  );
}

export default InvoiceLogFilter;
