import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { UPDATE_PROJECT_PERMISSION } from 'poly-security';
import { transformProjectEndDate } from 'poly-site-ui';
import {
  useHasUserAccessWithPermission,
  isChildRecurringProject,
} from 'poly-client-utils';
import {
  HeadingH1,
  HeadingH4,
  Header,
  Button,
  Holder,
  Title,
  S,
} from 'poly-book';

import InfoLoader from '../loader/info.js';
import BackArrow from '../back-arrow/index.js';
import ButtonLoader from '../loader/button.js';
import { ProjectPriority } from '../projects/priority.js';

const HeaderRow = styled(Header.Row)`
  min-height: 90px;
`;

export const isProjectLoaded = ({ projectId, priority }) =>
  !projectId && !priority;

const projectInfoPropTypes = {
  project: PropTypes.shape({
    projectId: PropTypes.string,
    priority: PropTypes.string,
  }),
  openEditProjectModal: PropTypes.func.isRequired,
};

export function EditProjectButton({ openEditProjectModal, project }) {
  const ifHasPermission = useHasUserAccessWithPermission(
    UPDATE_PROJECT_PERMISSION,
  );

  return (
    ifHasPermission && (
      <Button
        onClick={openEditProjectModal}
        disabled={isProjectLoaded(project)}
      >
        {isProjectLoaded(project) && <ButtonLoader />}
        <S type="title">Edit WO</S>
      </Button>
    )
  );
}

EditProjectButton.propTypes = projectInfoPropTypes;

function ProjectInfo({ openEditProjectModal, project }) {
  return (
    <Header.Sub>
      <HeaderRow>
        <Header.Block>
          <Holder margin={20}>
            <BackArrow />
            {isProjectLoaded(project) ? (
              <InfoLoader />
            ) : (
              <Title>
                <Title.Block>
                  <Title.Item>
                    <HeadingH1 lighter>{project.projectId}</HeadingH1>
                  </Title.Item>
                  <Title.Item>
                    <HeadingH4 lighter>Work Order</HeadingH4>
                  </Title.Item>
                </Title.Block>
                <Title.Block>
                  <Title.Item>
                    <S type="title" textColor="#4676b4">
                      {transformProjectEndDate(project)}
                    </S>
                  </Title.Item>
                  {isChildRecurringProject(project) && (
                    <Title.Item>
                      <ProjectPriority priority={project.priority} />
                    </Title.Item>
                  )}
                </Title.Block>
              </Title>
            )}
          </Holder>
        </Header.Block>
        <Header.Block>
          <EditProjectButton {...{ project, openEditProjectModal }} />
        </Header.Block>
      </HeaderRow>
    </Header.Sub>
  );
}

ProjectInfo.propTypes = projectInfoPropTypes;

ProjectInfo.defaultProps = {
  project: {},
};

export default ProjectInfo;
