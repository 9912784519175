import React from 'react';
import styled from 'styled-components';
import { Header, HeadingH1 } from 'poly-book';

const HeaderRow = styled(Header.Row)`
  min-height: 90px;
`;

function ReportsInfo() {
  return (
    <Header.Sub>
      <HeaderRow>
        <Header.Block>
          <HeadingH1 lighter>Report Directory</HeadingH1>
        </Header.Block>
      </HeaderRow>
    </Header.Sub>
  );
}

export default ReportsInfo;
