import * as R from 'ramda';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { ASC_SORT_ORDER } from 'poly-constants';

import { STANDARD_SIZE } from '../constants/index.js';
import { TECHNICIANS } from '../constants/staff.js';
import { setStaffSidebar } from '../redux/actions/index.js';
import {
  allStaffQuery,
  techniciansQuery,
  managersQuery,
  activeProjectsQuery,
  overdueProjectsQuery,
  lastThirtyProjectsQuery,
  requestersQuery,
} from '../utils/staff/staff-filters.js';
import {
  userProjectsQuery,
  getUser,
  getStaff,
  usersChanged,
  getTechnicianStats,
  userProjectsCountQuery,
} from '../queries/index.js';
import { resendUserEnrollmentEmail } from '../queries/staff/update-user.js';
import { useReactiveEntities } from './useReactiveEntities.js';
import { hasUserTechnicianGroup } from '../utils/user/index.js';
import { userFragment } from '../queries/fragments.js';

const getStaffUsersQuery = gql`
  query GET_STAFF_USERS_QUERY($input: CollectionSearchParams) {
    searchUsers(input: $input) {
      hits {
        _id
        createdAt
        email
        firstName
        fullName
        lastName
        status
        title
        updatedAt
        profile {
          cellPhoneNumber
        }
        userGroups {
          userGroup {
            _id
            name
          }
        }
      }
    }
  }
`;

export const SORTING = {
  sort: [
    { 'profile.fullName.keyword': ASC_SORT_ORDER },
    { status: ASC_SORT_ORDER },
  ],
};

// getRequesters :: User -> Object
const getRequesters = R.pipe(
  R.prop('hits'),
  R.defaultTo([]),
  R.filter(R.prop('email')),
);

export const useTechnicians = (searchTerm, clientId) => {
  const { loading, error, data } = useQuery(getStaffUsersQuery, {
    alias: 'useTechnicians',
    variables: {
      input: {
        searchTerm,
        size: STANDARD_SIZE,
        query: techniciansQuery(clientId),
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const technicians = data?.searchUsers.hits;

  return {
    loading,
    technicians,
    error,
    data,
  };
};

export const useManagers = (searchTerm, clientId) => {
  const { data, loading } = useQuery(getStaffUsersQuery, {
    alias: 'useManagers',
    variables: {
      input: {
        searchTerm,
        size: STANDARD_SIZE,
        query: managersQuery(clientId),
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const managers = data?.searchUsers.hits;

  return {
    loading,
    managers,
  };
};

const listUserGroupsQuery = gql`
  query LIST_SITE_USER_GROUPS {
    listUserGroups {
      _id
      name
      variables {
        id
        name
        type
      }
      accessItems {
        permission
        types {
          clientApp {
            equals
            flippedContains
          }
        }
      }
    }
  }
`;

export const useUserGroupsQuery = () => {
  const { data, loading } = useQuery(listUserGroupsQuery, {
    alias: 'useUserTypesQuery',
    notifyOnNetworkStatusChange: true,
  });

  return {
    userGroups: R.propOr([], 'listUserGroups', data),
    userTypesLoading: loading,
  };
};

const createUser = gql`
  mutation CREATE_USER_WITH_GROUP_MUTATION(
    $user: CreateUserWithUserGroupsInput!
  ) {
    createUserWithUserGroups(user: $user) {
      _id
      email
      profile {
        cellPhoneNumber
        workPhoneNumber
        faxPhoneNumber
      }
      fullName
      status
      userGroups {
        userGroup {
          _id
          name
        }
      }
    }
  }
`;

export const useCreateUser = () => {
  const dispatch = useDispatch();

  const [mutate] = useMutation(createUser, {
    alias: 'useCreateUser',
    refetchQueries: ({ data: { createUserWithUserGroups: user } }) => {
      dispatch(setStaffSidebar(user._id, !hasUserTechnicianGroup(user)));
      return [];
    },
  });

  return {
    createUser: (user) =>
      mutate({
        variables: {
          user,
        },
      }),
  };
};

const updateUser = gql`
  mutation UPDATE_USER_WITH_USER_GROUPS(
    $update: UpdateUserWithUserGroupsInput!
  ) {
    updateUserWithUserGroups(update: $update) {
      ...userFragment
    }
  }

  ${userFragment}
`;

export const useUpdateUserMutation = ({ shouldRefetch }) => {
  const refetchQueries = shouldRefetch ? ['userProjectsQuery'] : [];

  const [mutate] = useMutation(updateUser, {
    alias: 'useUpdateUserMutation',
    refetchQueries,
  });

  return {
    updateUser: (update) =>
      mutate({
        variables: {
          update,
        },
      }),
  };
};

export const useResendUserEnrollmentEmail = () => {
  const [mutate] = useMutation(resendUserEnrollmentEmail, {
    alias: 'useResendUserEnrollmentEmail',
  });

  return {
    resendUserEnrollmentEmail: (input) => mutate({ variables: { input } }),
  };
};

export const useUserQuery = (userId) => {
  const { data, loading } = useQuery(getUser, {
    alias: 'useUserQuery',
    variables: { id: userId },
    notifyOnNetworkStatusChange: true,
  });

  return {
    user: data?.user ?? {},
    loading,
  };
};

export const useUserProjectsQuery = (
  userId,
  activeQuery,
  projectsResultPropName,
) => {
  const { data, loading } = useQuery(userProjectsQuery, {
    alias: 'useUserProjectsQuery',
    variables: {
      id: userId,
      query: activeQuery,
      size: STANDARD_SIZE,
    },
    notifyOnNetworkStatusChange: true,
  });
  const user = data?.user;
  const projects = R.pathOr([], [projectsResultPropName, 'hits'], user);

  return {
    loading,
    projects,
    data: { loading, user },
    ownProps: { projectsResultPropName },
  };
};

const useStaffFilter = (typeFilter, clientId) => {
  const query =
    typeFilter === TECHNICIANS
      ? techniciansQuery(clientId)
      : allStaffQuery(clientId);

  const additionalVars = {
    activeProjectsQuery,
    overdueProjectsQuery,
    lastThirtyProjectsQuery,
  };

  return {
    ...SORTING,
    query,
    additionalVars,
  };
};

export const useStaff = (typeFilter, clientId, from, pageSize, searchTerm) => {
  const { query, additionalVars, sort } = useStaffFilter(typeFilter, clientId);

  const {
    data,
    loading,
    subscribeToMore,
    refetch,
    skip,
    networkStatus,
    result,
  } = useReactiveEntities({
    gqlQuery: getStaff,
    gqlChangedQuery: usersChanged,
    additionalVars,
    query,
    sort,
    from,
    pageSize,
    searchTerm,
    queryOptions: {},
    alias: 'useStaff',
    skipQuery: !clientId,
  });

  const users = data?.searchUsers.hits || [];
  const total = data?.searchUsers.total || 0;

  return {
    users,
    total,
    data,
    loading,
    subscribeToMore,
    refetch,
    skip,
    networkStatus,
    query,
    additionalVars,
    restProps: result,
  };
};

export const useRequesters = (searchRequesters) => {
  const query = requestersQuery;
  const searchTerm = searchRequesters;
  const skipQuery = R.isEmpty(searchRequesters);
  const noRequesterResults = R.ifElse(
    R.isEmpty,
    R.always(null),
    R.always('No Results Found'),
  )(searchRequesters);

  const { data, loading } = useReactiveEntities({
    gqlQuery: getStaffUsersQuery,
    gqlChangedQuery: usersChanged,
    query,
    searchTerm,
    skipQuery,
    alias: 'useRequesters',
  });

  const requestersLoading = loading || false;

  const requesters = getRequesters(data?.searchUsers);

  return {
    requesters,
    requestersLoading,
    noRequesterResults,
  };
};

export const useTechnicianStats = ({
  searchTerm,
  from,
  size,
  dateRange,
  query,
}) => {
  const { data, loading, fetchMore } = useQuery(getTechnicianStats, {
    alias: 'useTechnicianStats',
    variables: {
      input: {
        searchTerm,
        from,
        size,
        query,
        ...SORTING,
      },
      dateRange,
    },
  });

  const technicians = data?.searchUsers.hits;
  const total = data?.searchUsers.total;

  return {
    data,
    technicians,
    total,
    loading,
    fetchMore,
  };
};

export const useStaffCountQuery = ({ typeFilter, clientId }) => {
  const { query, additionalVars, sort } = useStaffFilter(typeFilter, clientId);

  const { data, loading, subscribeToMore, refetch, networkStatus, result } =
    useReactiveEntities({
      gqlQuery: getStaff,
      gqlChangedQuery: usersChanged,
      query,
      sort,
      additionalVars,
      alias: 'useStaffCountQuery',
      skipQuery: !clientId,
    });

  const count = data?.searchUsers.total || 0;

  return {
    count,
    data,
    loading,
    subscribeToMore,
    refetch,
    networkStatus,
    result,
  };
};

export const useUserProjectsCountQuery = ({
  id,
  query,
  projectsResultPropName,
}) => {
  const { data } = useQuery(userProjectsCountQuery, {
    variables: { id, input: { query } },
    notifyOnNetworkStatusChange: true,
    alias: 'useUserProjectsCountQuery',
  });

  const count = R.pathOr(0, ['user', projectsResultPropName, 'total'], data);

  return {
    count,
  };
};
