import React from 'react';
import PropTypes from 'prop-types';
import { Header, HeadingH1, Holder } from 'poly-book';
import { FilterByStatus } from 'poly-site-ui';

import { UpRow } from '../../styled.js';
import { ACTIVE, INACTIVE } from '../../constants/report/master-pm.js';
import TableSearchContainer from '../../containers/report-pm/search.js';
import { PrintAndExportButtons } from '../table/print-and-export-buttons.js';
import { useMRPmFilters } from '../../utils/mr-pm-projects/filter.js';
import { exportMasterRecurrentPMToXLS } from './export-to-xls.js';
import { useReportsPMCountQuery } from '../../hooks/report.js';

function FilterWrapper(props) {
  const { sort, query } = useMRPmFilters({ typeFilter: props.typeFilter });

  const { count } = useReportsPMCountQuery({ sort, query });

  return <FilterByStatus {...props} count={count} />;
}

FilterWrapper.propTypes = {
  typeFilter: PropTypes.string,
};

const FILTERS = [
  {
    title: 'Active',
    type: ACTIVE,
    color: '#9bdc53',
    showPip: true,
  },
  {
    title: 'INACTIVE',
    type: INACTIVE,
    color: '#babfd2',
    showPip: true,
  },
];

function ReportPMInfo({ onClick, activeFilter }) {
  return (
    <Header.Sub>
      <Header.Row>
        <Header.Block>
          <HeadingH1 lighter>Master Recurring PM Work Orders</HeadingH1>
        </Header.Block>
        <Header.Block>
          <PrintAndExportButtons
            printTitle="Master Recurring PM Work Orders"
            exportFunction={exportMasterRecurrentPMToXLS}
          />
        </Header.Block>
      </Header.Row>
      <UpRow>
        <Holder margin={10} center>
          {FILTERS.map(({ type, ...props }) => (
            <FilterWrapper
              active={activeFilter === type}
              typeFilter={type}
              key={type}
              {...{ onClick }}
              {...props}
            />
          ))}
        </Holder>
        <Header.Block>
          <TableSearchContainer />
        </Header.Block>
      </UpRow>
    </Header.Sub>
  );
}

ReportPMInfo.propTypes = {
  onClick: PropTypes.func.isRequired,
  activeFilter: PropTypes.string.isRequired,
};

export default ReportPMInfo;
