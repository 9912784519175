import * as R from 'ramda';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { PurchaseOrderStatus } from 'poly-constants';
import { debounce } from 'poly-utils';

import {
  GeneralError,
  EditWOPOSuccess,
  EditNonWOPOSuccess,
} from '../../constants/alerts.js';
import {
  PAID,
  APPROVED,
  CANCELLED,
  scopeTypes,
  PENDING_APPROVAL,
} from '../../constants/purchase-orders.js';
import AddEditPORequest from '../../components/purchase-order/add-edit-modal.js';
import { mapStatusesToSelectProps } from '../../utils/purchase-orders/pos-utils.js';
import { getValue, removeCurrencyFormatting } from '../../util/general.js';
import { useUpdatePORequest } from '../../hooks/purchase-orders.js';
import { calculateTotal } from '../../utils/invoices/index.js';
import { useCommonPORequestLogic } from './add-po-request.js';
import { setPOsModal } from '../../redux/actions/index.js';
import useValidation from '../../hooks/useValidation.js';
import { useGlCodeSelectLogic } from './common.js';
import {
  usePOPermissionsHelper,
  usePOFileHandler,
} from '../../utils/purchase-orders/pos-hooks.js';

// getAvailableStatuses :: Object -> Object
export const getAvailableStatuses = ({ modalInfo, ifHasPermission }) =>
  R.ifElse(
    R.identity,
    () => mapStatusesToSelectProps(R.values(PurchaseOrderStatus)),
    () =>
      mapStatusesToSelectProps([
        PENDING_APPROVAL,
        CANCELLED,
        ...(modalInfo.status === APPROVED ? [APPROVED, PAID] : []),
      ]),
  )(ifHasPermission);

function EditPORequestModal({ modalInfo }) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const [status, setStatus] = useState(null);
  const [isAmountChanged, setIsAmountChanged] = useState(false);

  const {
    selectedProperty,
    setPropertySearch,
    setProperty,
    allSuppliers,
    supplierLoading,
    noSupplierResults,
    setLoading,
    setSupplierSearch,
    setSelectedScope,
    setSupplier,
    loading,
    selectedSupplier,
    selectedScope,
    propertyLoading,
    properties,
    noPropertyResults,
    data,
    result,
  } = useCommonPORequestLogic();

  const projectJobCostsRelation = modalInfo?.projectJobCostsRelation || false;

  const { updatePORequest } = useUpdatePORequest(projectJobCostsRelation);

  const { isAvailableForAll, ifHasPermission } = usePOPermissionsHelper({
    orderProp: 'modalInfo',
    userProp: 'user',
    restProps: { user, modalInfo },
  });

  const { file, wasAdded, wasRemoved, onAddFile, onRemoveFile, setFile } =
    usePOFileHandler();

  const setFileDebounced = useCallback(
    debounce(400)(() => {
      setFile(
        modalInfo.attachments.length > 0
          ? {
              name: modalInfo.attachments[0].fileName,
              src: modalInfo.attachments[0].url,
              size: modalInfo.attachments[0].fileSize,
            }
          : null,
      );
    }),
    [],
  );

  useEffect(() => {
    setSupplier({
      value: modalInfo.supplier._id,
      label: modalInfo.supplier.company.name,
    });
    setProperty({
      value: modalInfo.property?._id,
      label: modalInfo.property?.name,
    });
    setSelectedScope(
      scopeTypes.find((item) => item.value === modalInfo.isInScope),
    );
    setStatus(modalInfo.status);
    setFileDebounced();
  }, []);

  const { code, glCodes, onSelectCode } = useGlCodeSelectLogic(modalInfo);

  const number = modalInfo?.cardNumber;
  const amount = modalInfo?.amount;
  const description = modalInfo?.description;
  const project = modalInfo?.project?.projectId;
  const invoicesAmount = calculateTotal(modalInfo?.invoices);
  const previousFileUrl = modalInfo.attachments[0]?.url || null;
  const shouldDisplayStatus = isAvailableForAll || ifHasPermission;

  const availableStatuses = getAvailableStatuses({
    modalInfo,
    ifHasPermission,
  });

  const {
    errors,
    setError,
    onChange,
    validateOnBlur,
    resetError,
    validate,
    validateField,
  } = useValidation({
    validationRules: {
      supplier: [{ rule: 'required', message: 'Please Select Supplier' }],
      amount: [
        {
          rule: (amountVal) =>
            removeCurrencyFormatting(amountVal) < invoicesAmount,
          message:
            'You can not edit the PO amount for less than the invoices attached',
        },
        { rule: 'currency' },
        { rule: 'required' },
      ],
      ...(isAmountChanged && { update: [{ rule: 'required' }] }),
      description: [{ rule: 'required' }],
      ...(!project && {
        property: [{ rule: 'required', message: 'Please Select Property' }],
      }),
    },
  });

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);

      const glCode = code?.value;

      const errorsValue = validate({
        supplier: selectedSupplier,
        amount: getValue('amount', e),
        description: getValue('description', e),
        ...(isAmountChanged && { update: getValue('update', e) }),
        ...(!project && { property: selectedProperty }),
      });

      if (errorsValue.isInvalid) {
        setLoading(false);
        return false;
      }

      const id = modalInfo?._id;
      const propertyId = selectedProperty?.value;

      const input = {
        status,
        supplierId: selectedSupplier.value,
        amount: removeCurrencyFormatting(getValue('amount', e)),
        isInScope: selectedScope.value,
        description: getValue('description', e),
        ...(isAmountChanged && {
          reasonForAmountChange: getValue('update', e),
        }),
        ...(propertyId && { propertyId }),
        glCode,
      };

      if (wasRemoved && !!previousFileUrl) {
        input.removeFileAttachments = [previousFileUrl];
      }

      if (wasAdded && !!file) {
        input.addFileAttachments = [{ upload: file.file }];
      }

      return updatePORequest(id, input)
        .then(() => {
          setLoading(false);
          setError(null);
          dispatch(setPOsModal({ editPORequest: null }));
          toast.success(!project ? EditNonWOPOSuccess : EditWOPOSuccess);
        })
        .catch((error) => {
          setLoading(false);
          setError({ server: error.message });
          toast.error(GeneralError);
        });
    },
    [
      previousFileUrl,
      isAmountChanged,
      updatePORequest,
      selectedProperty,
      selectedSupplier,
      selectedScope,
      wasAdded,
      wasRemoved,
      modalInfo,
      validate,
      setLoading,
      setError,
      dispatch,
      project,
      status,
      file,
      code,
    ],
  );

  const closeModal = useCallback(
    () => dispatch(setPOsModal({ editPORequest: null })),
    [dispatch, setPOsModal],
  );

  const selectSupplier = useCallback(
    (supplier) => {
      setSupplier(supplier);
      validateField('supplier', supplier?.value);
    },
    [setSupplier, validateField],
  );

  const selectProperty = useCallback(
    (property) => {
      setProperty(property);
      validateField('property', property?.value);
    },
    [setProperty, validateField],
  );

  const selectStatus = useCallback(
    (statusValue) => setStatus(statusValue?.value),
    [setStatus],
  );

  const onAmountEdit = useCallback(
    (e) => {
      const newAmount = e.target.value;
      validateField('amount', newAmount);
      if (removeCurrencyFormatting(newAmount) !== amount) {
        setIsAmountChanged(true);
      } else {
        setIsAmountChanged(false);
      }
    },
    [amount, validateField, setIsAmountChanged, removeCurrencyFormatting],
  );

  return (
    <AddEditPORequest
      {...data}
      {...result}
      {...modalInfo}
      type="edit"
      file={file}
      loading={loading}
      errors={errors}
      onChange={onChange}
      closeModal={closeModal}
      onSubmit={onSubmit}
      number={number}
      status={status}
      project={project}
      amount={amount}
      description={description}
      availableStatuses={availableStatuses}
      shouldDisplayStatus={shouldDisplayStatus}
      code={code}
      glCodes={glCodes}
      supplierLoading={supplierLoading}
      allSuppliers={allSuppliers}
      selectedSupplier={selectedSupplier}
      selectSupplier={selectSupplier}
      setSupplierSearch={setSupplierSearch}
      scopeTypes={scopeTypes}
      selectedScope={selectedScope}
      setSelectedScope={setSelectedScope}
      isAmountChanged={isAmountChanged}
      propertyLoading={propertyLoading}
      properties={properties}
      selectedProperty={selectedProperty}
      selectProperty={selectProperty}
      setPropertySearch={setPropertySearch}
      selectStatus={selectStatus}
      validateOnBlur={validateOnBlur}
      resetError={resetError}
      onAmountEdit={onAmountEdit}
      noPropertyResults={noPropertyResults}
      noSupplierResults={noSupplierResults}
      onAddFile={onAddFile}
      onRemoveFile={onRemoveFile}
      onSelectCode={onSelectCode}
    />
  );
}

EditPORequestModal.propTypes = {
  modalInfo: PropTypes.shape({
    supplier: PropTypes.shape({
      _id: PropTypes.string,
      company: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    property: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
    attachments: PropTypes.arrayOf(
      PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ),
    ),
    invoices: PropTypes.arrayOf(
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.object,
          PropTypes.string,
          PropTypes.number,
        ]),
      ),
    ),
    isInScope: PropTypes.bool,
    cardNumber: PropTypes.string,
    amount: PropTypes.number,
    description: PropTypes.string,
    status: PropTypes.string,
    _id: PropTypes.string,
    project: PropTypes.shape({
      projectId: PropTypes.string,
    }),
    projectJobCostsRelation: PropTypes.bool,
  }),
};

export default function () {
  const modalInfo = useSelector(
    (state) => state.purchaseOrders.modals.editPORequest,
  );

  return modalInfo ? <EditPORequestModal modalInfo={modalInfo} /> : null;
}
