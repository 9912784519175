import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header, HeadingH1, HeadingH4, Holder, Title } from 'poly-book';

import InfoLoader from '../loader/info.js';
import BackArrow from '../back-arrow/index.js';

const HeaderRow = styled(Header.Row)`
  min-height: 90px;
`;

const isPropertyLoaded = ({ name }) => !!name;

function PropertyInfo({ property }) {
  return (
    <Header.Sub>
      <HeaderRow>
        <Header.Block>
          <Holder margin={20}>
            <BackArrow />
            {!isPropertyLoaded(property) ? (
              <InfoLoader />
            ) : (
              <Title>
                <Title.Block>
                  <Title.Item>
                    <HeadingH1 lighter>{property.name}</HeadingH1>
                  </Title.Item>
                  <Title.Item>
                    <HeadingH4 lighter>Property</HeadingH4>
                  </Title.Item>
                </Title.Block>
              </Title>
            )}
          </Holder>
        </Header.Block>
      </HeaderRow>
    </Header.Sub>
  );
}

PropertyInfo.propTypes = {
  property: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
  }),
};

PropertyInfo.defaultProps = {
  property: {},
};

export default PropertyInfo;
