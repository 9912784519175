import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { CREATE_PO_INVOICE_PERMISSION } from 'poly-security';
import {
  IconButton,
  HeadingH5,
  Heading,
  Widget,
  Holder,
  Icon,
  Grid,
  S,
} from 'poly-book';

import AddInvoiceModal from '../../containers/invoice/add-invoice.js';
import EditInvoiceModal from '../../containers/invoice/edit-invoice.js';
import BlockInvoiceModal from '../../containers/invoice/confirm-modal.js';

const Wrapper = styled(Widget.Item)`
  height: 100%;
  display: grid;
  grid-row-gap: 20px;
  grid-template-rows: auto auto 1fr;
`;

const StyledHolder = styled(Holder)`
  justify-content: flex-end;
`;

const Total = styled(S)`
  padding-right: 60px;
`;

function AddInvoiceButton({ addInvoice }) {
  const ifHasPermission = useHasUserAccessWithPermission(
    CREATE_PO_INVOICE_PERMISSION,
  );

  return (
    ifHasPermission && (
      <IconButton fill="#ff8d02" onClick={addInvoice} hover>
        <S type="title">Add Invoice</S>
        <Icon name="add" fill="#ff8d02" dimensions={{ width: 30 }} />
      </IconButton>
    )
  );
}
AddInvoiceButton.propTypes = {
  addInvoice: PropTypes.func.isRequired,
};

function Invoices({
  isEmpty,
  total,
  gridColumns,
  rows,
  columns,
  headers,
  formats,
  props,
  type,
  addInvoice,
}) {
  return (
    <Wrapper>
      <Heading>
        <HeadingH5>Invoices</HeadingH5>
        <AddInvoiceButton {...{ addInvoice }} />
      </Heading>
      {!isEmpty && (
        <>
          <Grid columns={gridColumns}>
            <Grid.Head>
              {headers.map((header, i) => (
                <Grid.Cell
                  key={header || 'empty-header-key'}
                  {...R.propOr({}, 'align')(props[i])}
                >
                  <S type="title" textColor="#90939d">
                    {header}
                  </S>
                </Grid.Cell>
              ))}
            </Grid.Head>
            <Grid.Body>
              {rows.map((row) => (
                <Grid.Row key={row._id}>
                  {columns.map((_, i) => (
                    <Grid.Cell
                      // eslint-disable-next-line
                      key={`${row._id}_${headers[i]}`}
                      {...R.propOr({}, 'align')(props[i])}
                    >
                      {R.compose(formats[i], columns[i])({ ...row })}
                    </Grid.Cell>
                  ))}
                </Grid.Row>
              ))}
            </Grid.Body>
          </Grid>
          <StyledHolder>
            <Total type="title" textColor="#5d688c">
              Total
            </Total>
            <Total type="content" bold>
              {total}
            </Total>
          </StyledHolder>
        </>
      )}
      {R.equals(type, 'add') && <AddInvoiceModal />}
      {R.equals(type, 'edit') && <EditInvoiceModal />}
      <BlockInvoiceModal />
    </Wrapper>
  );
}

Invoices.propTypes = {
  isEmpty: PropTypes.bool.isRequired,
  total: PropTypes.string.isRequired,
  gridColumns: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  columns: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
  formats: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
  props: PropTypes.arrayOf(
    PropTypes.shape({
      align: PropTypes.objectOf(PropTypes.string),
    }),
  ),
  type: PropTypes.string,
  addInvoice: PropTypes.func.isRequired,
};

Invoices.defaultProps = {
  type: null,
  props: [],
};

export default Invoices;
