import React from 'react';
import PropTypes from 'prop-types';
import { Heading, HeadingH3, Icon, IconButton, Input, Modal } from 'poly-book';
import styled from 'styled-components';

import Technicians from '../../containers/pm-projects/technicians-modal/technicians.js';

const Content = styled(Modal.Item.withComponent(Modal.Content))`
  width: 550px;
  height: 300px;
  padding-right: 5px;
  position: relative;
`;

function TechniciansModal({ show, closeModal, onChange, searchTerm }) {
  return (
    <Modal {...{ show }}>
      <Modal.Item margin={20}>
        <Heading>
          <HeadingH3 lighter>Assign PM Technician</HeadingH3>
          <IconButton onClick={closeModal}>
            <Icon name="close" fill="#888b97" dimensions={{ width: 10 }} />
          </IconButton>
        </Heading>
      </Modal.Item>
      <Modal.Item margin={30}>
        <Input
          placeholder="Start Searching by Name"
          name="search-by-name"
          {...{ onChange }}
          autoFocus
        />
      </Modal.Item>
      <Content scroll>
        <Technicians {...{ searchTerm }} />
      </Content>
    </Modal>
  );
}

TechniciansModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
};

export default TechniciansModal;
