import React from 'react';
import PropTypes from 'prop-types';
import { Header, HeadingH1, HeadingH4, Holder, Title } from 'poly-book';
import styled from 'styled-components';

import BackArrow from '../back-arrow/index.js';
import InfoLoader from '../loader/info.js';

const HeaderRow = styled(Header.Row)`
  min-height: 90px;
`;

function MrPmProjectInfo({ loading, info }) {
  return (
    <Header.Sub>
      <HeaderRow>
        <Header.Block>
          <Holder margin={20}>
            <BackArrow />
            {loading ? (
              <InfoLoader />
            ) : (
              <Title>
                <Title.Block>
                  <Title.Item>
                    <HeadingH1 lighter>{info.maintenancePlanId}</HeadingH1>
                  </Title.Item>
                  <Title.Item>
                    <HeadingH4 lighter>
                      Master Recurring PM Work Order
                    </HeadingH4>
                  </Title.Item>
                </Title.Block>
              </Title>
            )}
          </Holder>
        </Header.Block>
      </HeaderRow>
    </Header.Sub>
  );
}

MrPmProjectInfo.propTypes = {
  loading: PropTypes.bool.isRequired,
  info: PropTypes.shape({
    maintenancePlanId: PropTypes.string,
  }),
};

MrPmProjectInfo.defaultProps = {
  info: {},
};

export default MrPmProjectInfo;
