import * as R from 'ramda';
import React, { memo } from 'react';
import styled from 'styled-components';
import { func, number, shape, string } from 'prop-types';
import { FilePond, registerPlugin } from 'react-filepond';
import FilepondPluginFileRename from 'filepond-plugin-file-rename';
import 'filepond/dist/filepond.min.css';

const Wrapper = styled.div`
  position: relative;
`;

const FileClicker = styled.a`
  position: absolute;
  top: 15px;
  left: 60px;
  width: calc(100% - 75px);
  height: calc(100% - 30px);
  cursor: pointer;
`;

registerPlugin(FilepondPluginFileRename);

const fileRenameFunction = ({ basename, extension }) =>
  encodeURI(basename) + extension;

export const FileArea = memo(
  ({ file, label, buttonLabel, onAddFile, onRemoveFile, ...props }) => {
    const setRef = (innerRef) => {
      if (innerRef) {
        innerRef._pond.setOptions({ fileRenameFunction });
      }
    };

    const LABEL = `${label} <span class="filepond--label-action">${buttonLabel}</span>`;
    const { files, fileLink } = R.ifElse(
      R.identity,
      R.ifElse(
        R.has('src'),
        // we format file received from server
        R.applySpec({
          fileLink: R.prop('src'),
          files: R.pipe(
            R.objOf('file'),
            R.mergeDeepLeft({ type: 'local' }),
            R.objOf('options'),
            R.of,
          ),
        }),
        // we format file attached by user
        R.applySpec({
          fileLink: ({ file: fileObj }) => URL.createObjectURL(fileObj),
          files: R.of,
        }),
      ),
      R.always({
        files: [],
        fileLink: null,
      }),
    )(file);

    return (
      <Wrapper>
        <FilePond
          {...props}
          ref={setRef}
          files={files}
          labelIdle={LABEL}
          onaddfilestart={onAddFile}
          onremovefile={onRemoveFile}
          className="Poly-File"
          instantUpload={false}
          server={{
            process: null,
            revert: null,
            restore: null,
            load: null,
            fetch: null,
          }}
        />
        {!!file && <FileClicker href={fileLink} target="_blank" />}
      </Wrapper>
    );
  },
);

FileArea.propTypes = {
  file: shape({
    name: string,
    src: string,
    size: number,
  }),
  label: string,
  buttonLabel: string,
  onAddFile: func,
  onRemoveFile: func,
};

FileArea.defaultProps = {
  label: 'Drag & Drop your file or',
  buttonLabel: 'Browse',
  file: null,
};
FileArea.displayName = 'FileArea';
