import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CREATE_PURCHASE_ORDER_PERMISSION } from 'poly-security';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import {
  IconButton,
  HeadingH5,
  Heading,
  Widget,
  Grid,
  Icon,
  Pip,
  S,
} from 'poly-book';

import { InvertedRow } from '../../utils/purchase-orders/index.js';
import { DetailsRow, MultipleDetailsRow } from '../../utils/wrappers.js';
import SendSupplierConfirmationModal from '../../containers/purchase-order/send-sup-conf-modal.js';
import { getLinkByProjectType } from '../../utils/projects/get-link-by-type.js';

const Title = styled(Heading)`
  margin-bottom: 20px;
`;
const SupplierConfirmationWrapper = styled.div`
  padding-top: 20px;
`;

function SendConfirmationButton({ onOpenPOSupplierConfirmModal }) {
  const ifHasPermission = useHasUserAccessWithPermission(
    CREATE_PURCHASE_ORDER_PERMISSION,
  );

  return (
    ifHasPermission && (
      <IconButton fill="#ff8d02" onClick={onOpenPOSupplierConfirmModal} hover>
        <S type="title">Send PO Supplier Confirmation</S>
        <Icon name="add" fill="#ff8d02" dimensions={{ width: 30 }} />
      </IconButton>
    )
  );
}
SendConfirmationButton.propTypes = {
  onOpenPOSupplierConfirmModal: PropTypes.func.isRequired,
};

function PurchaseOrderDetails({
  project,
  status,
  supplier,
  inScope,
  description,
  property,
  amount,
  spent,
  balance,
  files,
  onOpenPOSupplierConfirmModal,
  glCode,
}) {
  return (
    <>
      <Widget.Item>
        <Title>
          <HeadingH5>
            {project ? (
              <>
                WO#
                {getLinkByProjectType(project)}
              </>
            ) : (
              'Non Work Order'
            )}
          </HeadingH5>
          <Pip color={R.prop('color', status)}>
            <S type="title">{R.prop('title', status)}</S>
          </Pip>
        </Title>
        <Grid columns="120px 1fr" simple>
          <DetailsRow title="Supplier" value={supplier} />
          <DetailsRow title="In Scope" value={inScope} />
          <DetailsRow title="Description" value={description} />
          <DetailsRow title="Property" value={property} />
          <MultipleDetailsRow title="File" list={files} linkType />
        </Grid>
      </Widget.Item>
      <Widget.Item>
        <Title>
          <HeadingH5>PO Information</HeadingH5>
        </Title>
        <Grid columns="120px 100px" simple>
          <InvertedRow title="PO Amount" value={amount} />
          <InvertedRow title="Spent to Date" value={spent} />
          <InvertedRow title="PO Balance" value={balance} />
          <InvertedRow title="GL Code" value={glCode} />
          <SupplierConfirmationWrapper>
            <SendConfirmationButton {...{ onOpenPOSupplierConfirmModal }} />
          </SupplierConfirmationWrapper>
        </Grid>
      </Widget.Item>

      <SendSupplierConfirmationModal />
    </>
  );
}

PurchaseOrderDetails.propTypes = {
  project: PropTypes.shape({
    projectId: PropTypes.string,
  }),
  status: PropTypes.shape({
    color: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      fileName: PropTypes.string,
    }),
  ).isRequired,
  supplier: PropTypes.string.isRequired,
  inScope: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  property: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  spent: PropTypes.string.isRequired,
  balance: PropTypes.string.isRequired,
  onOpenPOSupplierConfirmModal: PropTypes.func.isRequired,
  glCode: PropTypes.string.isRequired,
};

PurchaseOrderDetails.defaultProps = {
  project: null,
};

export default PurchaseOrderDetails;
