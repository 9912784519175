import React from 'react';
import styled from 'styled-components';
import { string, node, shape, arrayOf } from 'prop-types';
import { HeadingH5, Holder, Widget, List } from 'poly-book';
import { fileNameFormatter, fileTypeFormatter } from 'poly-site-ui';

import { renderList, Content } from '../../../utils/mr-pm-projects/index.js';

const StyledWidgetItem = styled(Widget.Item)`
  display: grid;
  grid-row-gap: 20px;
`;

function WidgetItem({ title, children }) {
  return (
    <StyledWidgetItem>
      <HeadingH5>{title}</HeadingH5>
      {children}
    </StyledWidgetItem>
  );
}

WidgetItem.propTypes = {
  title: string.isRequired,
  children: node.isRequired,
};

function Tasks({ items }) {
  return (
    <List>
      {renderList(items, (item) => (
        <List.Item key={item}>
          <Content>{item}</Content>
        </List.Item>
      ))}
    </List>
  );
}

Tasks.propTypes = {
  items: arrayOf(string).isRequired,
};

function Files({ items }) {
  return renderList(items, ({ _id, fileType, ...file }) => (
    <Holder key={_id} margin={20} center>
      {fileTypeFormatter(fileType)}
      {fileNameFormatter(file)}
    </Holder>
  ));
}

Files.propTypes = {
  items: arrayOf(
    shape({
      _id: string.isRequired,
      url: string.isRequired,
      fileName: string.isRequired,
      fileType: string.isRequired,
    }),
  ).isRequired,
};

function Checklist({ checklist, materials, notes, files }) {
  return (
    <>
      <WidgetItem title="Tasks">
        <Tasks items={checklist} />
      </WidgetItem>
      <WidgetItem title="Materials">
        <Tasks items={materials} />
      </WidgetItem>
      <WidgetItem title="Notes">
        <Content>{notes}</Content>
      </WidgetItem>
      <WidgetItem title="PM Files">
        <Files items={files} />
      </WidgetItem>
    </>
  );
}

Checklist.propTypes = {
  checklist: arrayOf(string).isRequired,
  materials: arrayOf(string).isRequired,
  notes: string.isRequired,
  files: arrayOf(
    shape({
      _id: string,
      fileType: string,
    }),
  ).isRequired,
};

export default Checklist;
