import React from 'react';
import styled from 'styled-components';
import { Header, HeadingH1, Holder } from 'poly-book';

import TableSearchContainer from '../../containers/approved-pos/search.js';
import { PrintAndExportButtons } from '../table/print-and-export-buttons.js';
import { exportPOWaitingForInvoiceToXLS } from './export-to-xls.js';

const Wrapper = styled(Header.Row)`
  padding: 15px 0;
`;

function ApprovedPOsFilter() {
  return (
    <Header.Sub>
      <Wrapper>
        <Header.Block>
          <HeadingH1 lighter>Approved POs Waiting on Invoice</HeadingH1>
        </Header.Block>
        <Header.Block>
          <PrintAndExportButtons
            printTitle="Approved POs Waiting on Invoice"
            exportFunction={exportPOWaitingForInvoiceToXLS}
            marginRight="20px"
          />
          <Holder margin={20}>
            <TableSearchContainer />
          </Holder>
        </Header.Block>
      </Wrapper>
    </Header.Sub>
  );
}

export default ApprovedPOsFilter;
