import React from 'react';
import PropTypes from 'prop-types';
import { Header, HeadingH1, S } from 'poly-book';

import { formatPlaceholder } from '../../util/select.js';
import Select from '../select/index.js';
import { PrintAndExportButtons } from '../table/print-and-export-buttons.js';

function Label({ label }) {
  return <S type="title">{label}</S>;
}

Label.propTypes = {
  label: PropTypes.string.isRequired,
};

function YearSelectorHeader({
  title,
  onYearSelect,
  options,
  year,
  exportFunction,
  tablePropsToVariablesForXlsQuery,
}) {
  return (
    <Header.Sub>
      <Header.Row>
        <Header.Block>
          <HeadingH1 lighter>{title}</HeadingH1>
        </Header.Block>
        <Header.Block>
          <Select
            value={year}
            onChange={onYearSelect}
            placeholder={formatPlaceholder('Year')}
            options={options}
            optionRenderer={Label}
          />
        </Header.Block>
        <Header.Block>
          <PrintAndExportButtons
            printTitle={title}
            exportFunction={exportFunction}
            tablePropsToVariablesForXlsQuery={tablePropsToVariablesForXlsQuery}
          />
        </Header.Block>
      </Header.Row>
    </Header.Sub>
  );
}

YearSelectorHeader.propTypes = {
  year: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
  onYearSelect: PropTypes.func.isRequired,
  exportFunction: PropTypes.func.isRequired,
  tablePropsToVariablesForXlsQuery: PropTypes.func,
};

export default YearSelectorHeader;
